import './App.css';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import SignUp from './pages/SignUp';
import React, { useEffect, useState } from 'react';

let isLogged = false;
let isLoggedCss = !isLogged ? 'blocked' : null;

function HomePage() {
  return (
    <div className="App">
      <div className="BackgroundContainer">
        <div className="BackgroundLeft"></div>
        <div className="BackgroundCenter"></div>
        <div className="BackgroundRight"></div>
      </div>

      <div className="Menu">
        <div className="MenuButtonsContainer">
          <div className={`MenuButton StartGameButton ${isLoggedCss}`}>
            <div>Join game</div>
            {!isLogged ? <p>U must be signed in</p> : <></>}
          </div>
          <div className={`MenuButton CreateGameButton ${isLoggedCss}`}>
            <div>Create game</div>
            {!isLogged ? <p>U must be signed in</p> : <></>}
          </div>
          <div className="MenuButton CreateStoryButton">Create story</div>
          <div className="MenuButton CreateCharacterButton">Create character</div>
        </div>
      </div>

      {!isLogged ? (
        <div className="LoginContainer">
          <input placeholder="username/email" type="text" />
          <input placeholder="password" type="password" />
          <button>Sign in</button>
          <div className="SignUpLink">
            <p><Link to="/SignUp" className = 'Link'>New here? Sign up</Link></p>
          </div>
          <div className="SignUpLink">
            <p><Link to="/SignUp" className = 'Link'>Forgot password?</Link></p>
          </div>
        </div>
      ) : (
        <div className="LoginContainer">Logged in</div>
      )}
    </div>
  );
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* Define different routes */}
        <Route path="/" element={<HomePage />} />
        <Route path="/SignUp" element={<SignUp />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

import React, { useEffect, useState } from 'react';
import './css/SignUp.css';
import { Link } from "react-router-dom";

function SignUp() {
  useEffect(() => {
    const form = document.getElementById('signupForm');
    
    const handleSubmit = async (event) => {
      event.preventDefault();  // Zapobiegaj domyślnej akcji (odświeżenia strony)
      
      const username = document.querySelector('input[name="username"]').value;
      const email = document.querySelector('input[name="email"]').value;
      const birthday = document.querySelector('input[name="birthday"]').value;
      const password = document.getElementById('password').value;
      const rePassword = document.getElementById('re-password').value;

      // Walidacja haseł
      if (password !== rePassword) {
        alert('Passwords do not match. Please try again.');
        return;
      }

      // Tworzenie obiektu z danymi użytkownika
      const userData = {
        username: username,
        password: password,
        email: email,
        birthday: birthday
      };

      try {
        // Wysyłanie danych do backendu
        const response = await fetch('https://theendlessgame.online/users/add_user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(userData)
        });

        const result = await response.json();

        if (response.ok) {
          window.location.replace("http://localhost:3000/");
        } else {
          alert(result.error || 'Error occurred while adding user.');
        }
      } catch (error) {
        console.error('Error:', error);
        alert('An error occurred. Please try again.');
      }
    };

    form.addEventListener('submit', handleSubmit);

    return () => {
      form.removeEventListener('submit', handleSubmit);
    };
  }, []);

  return (
    <div className='Background'>
      <div className="App">
        <Link to='/' className='Link'><div className='GoBackButton'>{'< Start page'}</div></Link>
        <h1>Create an account</h1>
        <div className='FormContainer'>
          <form id='signupForm'>
            <label>Username</label>
            <input type='text' placeholder='username' name='username' required />
            <label>E-mail</label>
            <input type='text' placeholder='email' name='email' id='datePickerId' required />
            <label>Birthday</label>
            <input type='date' placeholder='birthday' name='birthday' min={new Date('1900/01/01').toISOString().split("T")[0]} max={new Date().toISOString().split("T")[0]} />
            <label>Password</label>
            <input type='password' placeholder='password' name='password' id='password' pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required title="Password must be at least 8 characters long and contain at least one lowercase letter, one uppercase letter, and one digit." />
            <label>Re-enter password</label>
            <input type='password' placeholder='Re-enter password' name='re-password' id='re-password' required />
            <button type="submit">Submit</button>
          </form>
        </div>
        <div id="message">
          <h3>Password must contain the following:</h3>
          <p id="letter" className="invalid">A <b>lowercase</b> letter</p>
          <p id="capital" className="invalid">A <b>capital (uppercase)</b> letter</p>
          <p id="number" className="invalid">A <b>number</b></p>
          <p id="length" className="invalid">Minimum <b>8 characters</b></p>
        </div>
      </div>
    </div>
  );
}

export default SignUp;